//settings
$fa-font-path: "../font";

// Colors //
$black: #000;
$white: #fff;
$primary-color: #808080;
$green-d:#0C2400;
$green:#104600;
$red:#A70606;
$gold:#D8A640;
$gray: #656565;

//Fonts
$primary-font: "freight-text-pro", serif; 
