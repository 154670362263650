@import "variables";
// includes
@import "includes/helpers";
@import "includes/normalize";
@import "includes/fonts";
@import "../../node_modules/magnific-popup/dist/magnific-popup";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";

html {
    font-size: 16px;
    line-height: 1.2;
    overflow-x: hidden;
}

body {
    background-color: $black;
    color: $white;
    font-family: $primary-font;
    font-weight: normal;
    font-size: rem(16);
    line-height: 1.2;
    overflow: hidden;
}

a{
    color: inherit;
    transition: .3s all;
    @include hover{
        color: $primary-color;
    }
}

.container{
    max-width: rem(1600);
}

.bgvideo{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    object-position: 50% 30%; 
    width: 100%;
    height: 100%;
    z-index: 0;    
    @media screen and (min-width:801px){
        &.mobile{
            display: none;
        }
    }
    @media screen and (max-width:800px){        
        &.desktop{
            display: none;
        }
    }
}

.page-wrap{
    position: relative;
    z-index: 1;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: space-between;
}

#header{
    padding: rem(30) 0;
    h1{
        width: 100%;
        max-width: rem(320);
        svg{
            width: 100%;
            height: auto;
        }
    }
    .container{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        .link{
            order: 1;
            position: relative;
        }
        .link+.link{
            order: 3;
        }
        // @media screen and (max-width:800px){
            flex-wrap: wrap;
            justify-content: center;
            .link+.link{
                margin-left: rem(20);
            }
            h1{
                margin: 0 auto;
            }
        // }

        .dropdown-link+ul{
            position: absolute;
            left: 0;
            top: 100%;
            opacity: 0;
            width: rem(250);
            margin: 0;
            @media screen and (max-width: 550px){
                left: auto;
                right: 0;
                a{
                    display: block;
                }
            }
        }

        .link:hover, .link:focus-within{
            .dropdown-link+ul{
                opacity: 1;
            }
        }
        
    }
    .title{
        margin: 0;
        order: 2;
        // @media screen and (max-width:800px){
            flex-basis: 100%;
            order: 0;
        // }
    }
    a{
        text-transform: uppercase;
    }
}

#footer{
    padding: rem(10) 0;
    .container{
        display: flex;
        justify-content: space-between;
        
        // @media screen and (max-width:800px){
            flex-direction: column;
            align-items: center !important;
        // }
    }

    
    // @media screen and (min-width:801px){
    //     .copyright{
    //         order: 1;
    //     }
    //     .follow{
    //         order: 3;
    //     }
    //     .feature{
    //         order: 2;
    //     }
    // }
    .copyright{
        flex: 1;
        align-self: flex-end;
        // @media screen and (max-width:800px){
            align-self: center;
        // }
    }
    .follow{
        flex: 1;
        text-align: right;
        // @media screen and (max-width:800px){
            align-self: center;
            text-align: center;
            margin-bottom: rem(30);
        // }
    }
    .feature{
        align-self: flex-start;
        text-align: center;
        flex-basis: rem(250);
        text-transform: uppercase;
        // @media screen and (max-width:800px){
            align-self: center;
            flex: 1;
            margin-bottom: rem(30);
        // }
        a{
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            text-decoration: underline;
            span{
                display: block;
                cursor: pointer;
                font-size: rem(20);
                &+span{
                    font-size: rem(14);
                }
            }
        }
    }
    .social{
        font-size: rem(18);
        padding: 0;
        margin: rem(15) 0 0;
        li{
            display: inline-block;
            &+li{
                margin-left: .5em;
            }
        }
    }

    .newsletter{
        display: inline-block;
        border-bottom: 1px solid $white;
        padding-bottom: rem(15);
        font-size: rem(14);
        input{
            background: none;
            border: none;
            color: $white;
            &::placeholder{
                color: $white;
            }
        }
        button{
            background: none;
            text-transform: uppercase;
            border: none;
            border-bottom: 1px solid;
            padding: 0;
            color: $white;
            transition: .3s all;
            cursor: pointer;
            @include hover{
                color: $primary-color;
            }
        }
    }
}

.copyright p{
    font-family: sans-serif;
    font-size: rem(10);
    text-transform: uppercase;
    text-align: left;
    width: 100%;
    max-width: rem(420);
    margin: 0;
    
    // @media screen and (max-width:800px){
        text-align: center;
    // }
    a{
        text-decoration: none;
    }
}

.popup{
    position: relative;
    margin: rem(30) auto;
    width: 100%;
    max-width: rem(600);
    background-color: $black;
    padding: rem(20) rem(20);
    font-size: rem(16);
    h2{
        margin-top: 0;
        text-align: center;
    }
    em{
        font-weight: bold;
    }
}